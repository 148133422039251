<template>
  <div>
    <div class="row gutter-b">
        <div class="col-md-12">
          <div class="card card-custom">
            <div class="card-header border-0 px-6">
              <div class="row align-items-center">
                </div>
                <div class="card-toolbar">
                  <b-button variant="success" v-b-modal.modal-department-edit @click="setNewDepartment()">
                    <span class="svg-icon svg-icon-md svg-icon-white" >
                      <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                    </span>
                    {{ $t("mealsystem.departments.button.create") }}
                  </b-button>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <!--begin::Card-->
        <div class="col-md-3 gutter-b" v-for="(department, index) in departments.data" :key="index">
          <div class="card card-custom" >
            <div class="card-header">
              <div class="card-title">
                  <h5 class="card-label">
                  {{ department.name}}
                  </h5>
              </div>
              <div class="card-toolbar"> 
                  <a class="btn btn-link btn-sm btn btn-clean btn-hover-light-danger btn-sm btn-icon" v-b-tooltip.top="$t('mealsystem.departments.title.delete_department')" v-b-modal.modal-department-delete @click="setDepartment(department)"><i class="ki ki-bold-close icon-sm text-danger"></i></a>
                  <a class="btn btn-link btn-sm btn btn-clean btn-hover-light-primary btn-sm btn-icon" v-b-tooltip.top="$t('button.settings')" v-b-modal.modal-department-edit @click="setDepartment(department)"><i class="ki ki-gear icon-md"></i></a>
              </div>
            </div>
          </div>
      </div>
    </div>

    <b-modal id="modal-department-delete" :okTitle="$t('button.delete')" okVariant="danger" :title="$t('mealsystem.departments.title.confirm_delete_department_title')">
       <span v-html="$t('mealsystem.departments.title.confirm_delete_department_text')"></span>
        <template #modal-footer="{ cancel }">
            <b-button variant="secondary" @click="cancel()" class="mr-3">
              {{ $t('button.cancel') }}
          </b-button> 
          <b-button variant="danger" @click="deleteDepartment(selectedDepartment)">
            {{ $t('mealsystem.departments.button.delete_department') }}
          </b-button>         
        </template>
    </b-modal>

    <b-modal id="modal-department-edit" :okTitle="$t('button.save')" okVariant="success" :title="$t('mealsystem.departments.title.edit_department')">
        <ValidationObserver ref="form">
            <ValidationProvider name="name" immediate rules="required|min:3" v-slot="{ errors }">
            <span class="text-muted mb-2 d-block">{{ $t('mealsystem.departments.label.name') }}</span>  
            <input 
                  type="text" 
                  class="form-control form-control-lg form-control-solid"
                  :placeholder="$t('mealsystem.departments.placeholder.name')"
                  v-model="selectedDepartment.name"
            >                
            <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
        </ValidationObserver>

          <template #modal-footer="{ cancel }">
              <b-button variant="secondary" @click="cancel()" class="mr-3">
                {{ $t('button.cancel') }}
            </b-button> 
            <b-button variant="success" @click="updateDepartment(selectedDepartment)">
              {{ $t('mealsystem.departments.button.save_department') }}
            </b-button>         
          </template>
      </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { checkErrors } from "@/core/utils/api-errors";

export default {
  data() {
    return {
      departments: null,
      loading: false,
      selectedDepartment: {}
    };
  },
  components: {

  },
  beforeMount() {
   this.getDepartments();
  },
  metaInfo () { 
      return { title: this.$t("mealsystem.meta.departments_list")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("mealsystem.meta.departments"), subtitle: this.$t("mealsystem.meta.departments_list") }]);
  },
  methods: {
    getDepartments() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/meal-system/departments")
        .then((response) => {
          this.departments = response.data;
          this.loading = false;
        });
    },
  	setDepartment(department){
    	this.selectedDepartment = department;
    },
    setNewDepartment(){
        this.selectedDepartment = {};
    },
    deleteDepartment(department){
      this.loading = true;
      ApiService.setHeader();      
      ApiService.apiPost("/meal-system/departments/" + department.department_id + "/delete")
        .then(() => {
            this.loading = false;
            this.$bvModal.hide('modal-department-delete');
            this.getDepartments();
        }).catch(error => {
            checkErrors(error);
      });
    },
    updateDepartment(department){
      this.loading = true;
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.loading = false;
          this.$toasted.error("Zkontrolujte zadané údaje.");
          return;
        } 

        if(department.department_id){
          this.endpoint = "/meal-system/departments/" + department.department_id + "/update"
        } else {
          this.endpoint  = "/meal-system/departments";
        }

        ApiService.setHeader();      
        ApiService.apiPost(this.endpoint,{
          'name': department.name,
        })
          .then(() => {
              this.loading = false;
              this.$bvModal.hide('modal-department-edit');
              this.getDepartments();
          }).catch(error => {
              checkErrors(error);
        });
      });
    }
  }
};
</script>
